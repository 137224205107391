<template>
    <div>
        <steps :active="3" ref="step" />
        <van-progress :percentage="process" style="position:fixed;width:100%;border-radius:0" :style="{ top: process_top + 'px' }" />
        <particles disableClick />
        <div class="container">
            <div class="total_question_container" ref="total_question_container" :style="{ width: `${question_width}px`, height: `${question_height}px` }">
                <div class="question_container" :style="{ marginLeft: `${-(question_index + 1) * question_width}px`, justifyContent: 'space-around' }">
                    <strong style="text-align:center;font-size:20px">注 意 事 项</strong>
                    <div style="padding:30px;text-indent:2em;font-size:14px;line-height:16px;color:#333">
                        <p>为确保结果准确，请您按照自身实际情况如实作答。</p>
                        <p>单选题选中后会自动跳转至下一道未作答的题目，多选题则需要在您作答完成后手动前往下一题。</p>
                        <p>该问卷共有{{ issue_list.length }}道题，您可在上方查阅当前答题进度。</p>
                    </div>
                    <van-button @click="question_index = 0" type="primary" style="width:90%;margin:0 auto">开 始</van-button>
                </div>
                <!-- 循环题目开始 -->
                <div class="question_container" v-for="(item, index) in issue_list" :key="index">
                    <div class="question_title">
                        <strong>{{ index + 1 }}. </strong>
                        <span>{{ item.text  }}</span><span>（</span><span>{{item.type=="不定项"? '多选':item.type}}</span><span>）</span>
                    </div>
                    <div class="question_options">
                        <van-radio-group v-model="answer_list[index]" v-if="item.type == '单选'" style="width:100%">
                            <van-radio style="line-height:26px;margin:8px 0px" v-for="option in item.option_list" :name="option.id" :key="option.id" @click="clickOption(index)">{{ option.text }}</van-radio>
                        </van-radio-group>
                        <van-checkbox-group v-model="answer_list[index]" v-if="item.type == '不定项'" style="width:100%">
                            <van-checkbox style="line-height:26px;margin:8px 0px" shape="square" v-for="option in item.option_list" :name="option.id" :key="option.id" @click="clickOption(index)">{{ option.text }}</van-checkbox>
                        </van-checkbox-group>
                        <van-field v-model="answer_list[index]" v-if="item.type == '填空'" style="width:100%" placeholder="请输入" />
                    </div>
                    <div class="question_result_button_padding"></div>
                </div>
                <!-- 循环题目结束 -->
                <div class="question_result_button" :style="{ width: `${question_width - 12}px`, bottom: `${question_index >= 0 ? 0 : -57}px` }">
                    <van-button style="width:100%" round @click="show_result = !show_result">{{ show_result ? '返 回' : '查看答题记录' }}</van-button>
                </div>
                <div class="question_result_panel" :style="{ marginTop: show_result ? '0px' : `${question_height - 1}px` }">
                    <van-cell-group style="margin:30px 0 70px">
                        <van-cell v-for="(item, index) in issue_list" :key="index" :title-style="{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 3 }" clickable is-link @click="[show_result, question_index] = [false, index]">
                            <template #title>
                                <strong>{{ index + 1 }}.</strong>
                                <span>{{ item.text  }}</span><span>（</span><span>{{item.type=="不定项"? '多选':item.type}}</span><span>）</span>
                            </template>
                            <van-tag type="success" v-if="answer_result_list[index]">已作答</van-tag>
                            <van-tag type="primary" v-else>未作答</van-tag>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
            <div class="button_container" :style="{ width: `${button_width}px`, height: `${question_index >= 0 ? 60 : 0}px` }">
                <van-button @click="question_index -= 1" v-show="question_index != 0" style="width:50%;margin:0 5px">上一题</van-button>
                <van-button @click="question_index += 1" v-show="question_index < issue_list.length - 1" type="primary" style="width:100%;margin:0 5px">下一题</van-button>
                <van-button @click="submit" v-show="question_index == issue_list.length - 1" type="primary" style="width:100%;margin:0 5px" :loading="submit_loading">完成</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import particles from "../components/particles";
import steps from "../components/steps";
export default {
    components: {
        particles,
        steps,
    },
    data() {
        return {
            question_width: 0,
            question_index: -1,
            question_finish: 0,
            issue_list: [
                // {
                //     question: '我是单选脏器问题1',
                //     type: '单选',
                //     options: [
                //         { id: 0, label: '我是选项1-1' },
                //         { id: 1, label: '我是选项1-2' },
                //         { id: 2, label: '我是选项1-3' },
                //         { id: 3, label: '我是选项1-4' },
                //     ],
                //     class: 'organ',
                // },
                // {
                //     question: '我是多选体质问题2',
                //     type: 'multiple',
                //     options: [
                //         { id: 0, label: '我是选项2-1' },
                //         { id: 1, label: '我是选项2-2' },
                //         { id: 2, label: '我是选项2-3' },
                //         { id: 3, label: '我是选项2-4' },
                //     ],
                //     class: 'corporeity',
                // },
            ],
            answer_list: [],
            answer_result_list: [],
            process: 0,
            show_result: false,
            submit_loading: false,
            process_top: 0,
            TiZhi_questionnaire: [],
            ZangQi_questionnaire: [],
            type_questionnaire: [],
        };
    },
    methods: {
        clickOption(index) {
            if (this.issue_list[index].type == "单选") {
                this.answer_result_list[index] = true;
                // 自动切换至下一道未作答的题目
                for (let i = index; i < this.issue_list.length; i++) {
                    if (!this.answer_result_list[i]) {
                        // 发现了下一道未作答的题，跳转过去
                        setTimeout(() => {
                            this.question_index = i;
                        }, 150);
                        break;
                    } else if (i == this.issue_list.length - 1) {
                        // 到最后也没有发现未作答的题，跳转至最后一道题
                        this.question_index = i;
                        break;
                    }
                }
            } else if (this.issue_list[index].type == "multiple") {
                this.answer_result_list[index] =
                    !!this.answer_list[index].length;
            } else if (this.issue_list[index].type == "不定项") {
                this.answer_result_list[index] = true;
            }
            let count = 0;
            for (let i = 0; i < this.issue_list.length; i++) {
                if (this.answer_result_list[i]) count++;
            }
            this.process = parseInt((count / this.issue_list.length) * 100);
        },
        setnub(){
                 window.onresize = () => {
            let container_width = document.body.clientWidth * 0.85;
            let brain_width = container_width * 0.2;
            this.brain_width = brain_width;
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.container_width = calc([920, 960], [960, 1280], [-40, 1, 0]);
            this.brain_width = calc(
                [552, 576],
                [920, 960],
                [0, 0.6, 0],
                this.container_width
            );
            this.background_text = "中医智能健康大脑 ".repeat(
                parseInt(this.container_width * 0.045)
            );
            if (this.health_chart != null)
                this.health_chart.resize({ width: this.container_width });
            if (this.disease_chart != null)
                this.disease_chart.resize({ width: this.container_width });
        };
        },
        submit() {
            if (this.process < 100) {
                for (let i = 0; i < this.issue_list.length; i++) {
                    if (!this.answer_result_list[i]) {
                        this.$toast.fail(`第${i + 1}题尚未进行作答`);
                        return;
                    }
                }
            }
            console.log(this.answer_list);
            const btype = [this.$route.query.type];
            if (this.$route.query.type == "主测评") {
                let TiZhi_answer_list = [];
                let ZangQi_answer_list = [];
                for (let index1 in this.TiZhi_questionnaire) {
                    for (let index2 in this.issue_list) {
                        if (
                            this.issue_list[index2].questionnaire == "体质" &&
                            this.issue_list[index2].index == index1
                        ) {
                            TiZhi_answer_list.push(
                                this.issue_list[index2].type == "单选"
                                    ? [this.answer_list[index2]]
                                    : this.answer_list[index2]
                            );
                            break;
                        }
                    }
                }
                for (let index1 in this.ZangQi_questionnaire) {
                    for (let index2 in this.issue_list) {
                        if (
                            this.issue_list[index2].questionnaire == "脏器" &&
                            this.issue_list[index2].index == index1
                        ) {
                            ZangQi_answer_list.push(
                                this.issue_list[index2].type == "单选"
                                    ? [this.answer_list[index2]]
                                    : this.answer_list[index2]
                            );
                            break;
                        }
                    }
                }
                this.$api("analyseIssue", {
                    TiZhi_answer_list,
                    ZangQi_answer_list,
                })
                    .then((res) => {
                        if (res.result) {
                            this.$toast({
                                type: "loading",
                                message: "提交成功，正在分析中...",
                            });
                            return this.$api("mainAnalyse");
                        }
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.$router.push({
                                path: "/report",
                                query: {
                                    one: true,
                                },
                            });
                        }, 2000);
                    });
            } else {
                let type_answer_list = [];
                // for (let index1 in this.type_questionnaire) {
                //     for (let index2 in this.issue_list) {
                //         if (
                //             this.issue_list[index2].questionnaire ==
                //                 this.$route.query.type &&
                //             this.issue_list[index2].index == index1
                //         ) {
                //             type_answer_list.push(
                //                 this.issue_list[index2].type == "不定项"
                //                     ? [this.answer_list[index2]]
                //                     : this.answer_list[index2]
                //             );
                //             break;
                //         }
                //     }
                // }

                this.answer_list.forEach((res) => {
                    if (!(res instanceof Array)) {
                        res = [res];
                    }
                    let a = [];
                    res.forEach((item) => {
                        a.push(item);
                    });
                    type_answer_list.push(a);
                });
                this.$api(`analyseIssueType`, { btype, type_answer_list })
                    .then((res) => {
                        if (res.result) {
                            this.$toast({
                                type: "loading",
                                message: "提交成功，正在分析中...",
                            });
                            return this.$api("singleSubjectAnalyse", {
                                btype,
                                type_answer_list,
                            });
                        }
                    })
                    .then((res) => {
                        this.$api("getHistoryReportList").then((res) => {
                            setTimeout(() => {
                                this.$router.push({
                                    path: "/single",
                                    query: {
                                        questionnaire_report_id:
                                            res.single_subject[btype][0].id,
                                    },
                                });
                            }, 2000);
                        });
                    });
            }
            // for (let i in this.issue_list) {
            //     if (this.issue_list[i].questionnaire == '体质') {
            //         TiZhi_answer_list.push({ index: this.issue_list[i].index, answer: this.issue_list[i].type == '单选' ? [this.answer_list[i]] : this.answer_list[i] });
            //     } else if (this.issue_list[i].questionnaire == '脏器') {
            //         ZangQi_answer_list.push({ index: this.issue_list[i].index, answer: this.issue_list[i].type == '单选' ? [this.answer_list[i]] : this.answer_list[i] });
            //     }
            // }
        },
    },
    created() {
        window.onresize = () => {
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.question_width = calc([520, 580], [640, 840], [-60, 0.8, 0]);
            this.question_height = (this.question_width / 3) * 5;
            this.button_width = calc([560, 720], [640, 840], [-60, 1, 0]);
        };
        window.onresize();
        if (this.$route.query.type !== "主测评") {
            const type = this.$route.query.type;
            this.$api("getQuestionnaire", {
                questionnaire: [type],
            }).then((res) => {
                this.type_questionnaire = [];
                for (let index in res[type].data.issue) {
                    let issue = res[type].data.issue[index];
                    issue.index = index;
                    issue.questionnaire = type;
                    issue.option_list = [];
                    for (let i in res[type].data.option[index]) {
                        issue.option_list.push({
                            id: parseInt(i),
                            text: res[type].data.option[index][i],
                        });
                    }
                    this.type_questionnaire.push(issue);
                }
                this.issue_list = [].concat(this.type_questionnaire);
                console.log(this.issue_list);
            });
        } else {
            this.$api("getQuestionnaire", {
                questionnaire: ["体质", "脏器"],
            }).then((res) => {
                this.TiZhi_questionnaire = [];
                for (let index in res["体质"].data.issue) {
                    let issue = res["体质"].data.issue[index];
                    issue.index = index;
                    issue.questionnaire = "体质";
                    issue.option_list = [];
                    for (let i in res["体质"].data.option[index]) {
                        issue.option_list.push({
                            id: parseInt(i),
                            text: res["体质"].data.option[index][i],
                        });
                    }
                    this.TiZhi_questionnaire.push(issue);
                }
                this.ZangQi_questionnaire = [];
                for (let index in res["脏器"].data.issue) {
                    let issue = res["脏器"].data.issue[index];
                    issue.index = index;
                    issue.questionnaire = "脏器";
                    issue.option_list = [];
                    for (let i in res["脏器"].data.option[index]) {
                        issue.option_list.push({
                            id: parseInt(i),
                            text: res["脏器"].data.option[index][i],
                        });
                    }
                    this.ZangQi_questionnaire.push(issue);
                }
                this.issue_list = [].concat(
                    this.TiZhi_questionnaire,
                    this.ZangQi_questionnaire
                );
            });
        }

    
    },
    mounted() {
        this.process_top = this.$refs["step"].$el.clientHeight - 1;
        
    },
};
</script>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.total_question_container {
    position: relative;
    background: rgba(238, 238, 238, 0.92);
    border-radius: 28px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}
.question_container {
    transition: all 0.4s ease-in-out;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.question_title {
    padding: 30px 20px 20px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
}
.question_options {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.question_result_button_padding {
    flex-shrink: 0;
    width: 100%;
    height: 56px;
}
.question_result_button {
    position: absolute;
    border-top: 1px solid #e8e8e8;
    background: rgba(248, 248, 248, 0.85);
    height: 44px;
    padding: 6px;
    bottom: 0px;
    transition: all 0.4s ease-in-out;
    transition-delay: 0.4s;
    z-index: 1000;
}
.question_result_panel {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: white;
    transition: all 0.6s ease-in-out;
    overflow: auto;
}
.button_container {
    line-height: 60px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;
    transition-delay: 0.4s;
    overflow: hidden;
}
</style>
